import {NxFormikNumberInput, NxSwitch} from '@nextbank/ui-components';
import React, {ChangeEvent} from 'react';
import {SetFieldValue} from '../../../../../../shared/model/formik.model';
import {PrefixTrans} from '../General';
import {
  DAYS_AFTER_EXPIRE,
  GeneralFormFields,
  SHOULD_EXPIRE,
  SUBMISSION_DATE_LIMIT,
  SUBMISSION_DATE_LIMIT_ENABLED
} from '../general.model';
import styles from './ExpiryDate.module.scss';

interface Props {
  values: GeneralFormFields;
  handleChange: (e: ChangeEvent) => void;
  setFieldValue: SetFieldValue;
}

export default function ExpiryDate({values, handleChange, setFieldValue}: Props): React.ReactElement {

    const handleSwitchChange = (e: React.ChangeEvent<Element>, fieldCode: string): void => {
      const target = e.target;
      if (target instanceof HTMLInputElement && !target.checked) {
        setFieldValue(fieldCode, undefined);
      }

      handleChange(e);
    };

    return (
      <>
        <div className={styles.row}>
          <NxSwitch name={SHOULD_EXPIRE}
                    checked={values[SHOULD_EXPIRE]}
                    onChange={(event): void => handleSwitchChange(event, DAYS_AFTER_EXPIRE)} />
          <p className={styles.rowLabel}>
            <PrefixTrans>EXPIRE_AFTER</PrefixTrans>
          </p>
          <NxFormikNumberInput label={<PrefixTrans>NUMBER_OF_DAYS</PrefixTrans>}
                               name={DAYS_AFTER_EXPIRE}
                               disabled={!values[SHOULD_EXPIRE]}
                               min={0} />
        </div>
        <div className={styles.row}>
          <NxSwitch name={SUBMISSION_DATE_LIMIT_ENABLED}
                    checked={values[SUBMISSION_DATE_LIMIT_ENABLED]}
                    onChange={(event): void => handleSwitchChange(event, SUBMISSION_DATE_LIMIT)} />
          <p className={styles.rowLabel}>
            <PrefixTrans>SUBMISSION_DATE_LIMIT_ENABLED</PrefixTrans>
          </p>
          <NxFormikNumberInput label={<PrefixTrans>SUBMISSION_DATE_LIMIT</PrefixTrans>}
                               name={SUBMISSION_DATE_LIMIT}
                               disabled={!values[SUBMISSION_DATE_LIMIT_ENABLED]}
                               min={0} />
        </div>
      </>
    );
}
